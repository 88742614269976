<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getToken, setUserData, setWxUserData, setToken } from "@/utils/auth"; // 验权
import { getUser, otherLogin, wxUserInfo, wxConfig } from "@/api/api";
import weui from "weui.js";
import utils from "@/utils/utils";
const appId = "wxed77e56f320518c0";
import sessionstorage from "sessionstorage";
import wechat from "@/utils/wechat";

export default {
  name: "App",
  data() {
    return {
      code: utils.getUrlKey("code"),
      track: utils.getUrlKey("track"),
      loading: null,
    };
  },
  created() {},
  mounted() {
    if (wechat.wxBrowser()) {
      this.handleWeChatAuth();
      let url = location.origin + this.$router.history.current.fullPath;
      console.log(url, "uqweqwe");
      wxConfig(url).then((resp) => {
        let wxConfig = resp.data;
        wxConfig.debug = false;
        wxConfig.jsApiList = [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",
          "onMenuShareQZone",
          "openLocation",
          "scanQRCode",
          "chooseWXPay",
        ];
        wxConfig.openTagList = ["wx-open-launch-weapp"];
        window.wx.config(wxConfig);
      });
    }
  },
  methods: {
    async handleWeChatAuth() {
      let res = await getUser();
      console.log(res, "asdasdqwe");

      //用户未登录从新授权
      if (res.code === 1100) {
        await this.wxAuth(this.code);
      } else {
        setUserData(res.data);
        this.umengSetMetaInfo(res.data);
      }
    },

    wxAuth(code) {
      //let loading;
      let that = this;
      return new Promise(function (resolve, reject) {
       // loading = weui.loading("登录中");
        let url = "";
        let locationHref = escape(window.location.href);
        if (code == null) {
          // 设置 - 重新登录判断跳转首页
          if (
            unescape(locationHref) == "https://wap2.esnewcollege.com/setting"
          ) {
            url = escape("https://wap2.esnewcollege.com/home");
          } else {
            url = escape(window.location.href);
          }
          let scope = "snsapi_userinfo";
          console.log(appId, "this.appId");
          //WeChatAuth
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appId +
            "&redirect_uri=" +
            url +
            "&response_type=code&scope=" +
            scope +
            "&state=STATE#wechat_redirect";
        } else {
          wxUserInfo(code).then((resp) => {
            if (resp.code === 0) {
              setWxUserData(resp.data);

              otherLogin({
                uoid: resp.data.unionid,
                nickname: resp.data.nickname,
                head_img: resp.data.headimgurl,
                gender: resp.data.sex,
                wx_openid: resp.data.openid,
                third_party: "wxh5",
              }).then((res) => {
                if (res.code === 0) {
                  setToken(res.data.token);
                  getUser().then((userRes) => {
                    setUserData(userRes.data);
                    that.umengSetMetaInfo(userRes.data);
                  //  loading.hide();
                    //过滤微信code
                    let query = that.$router.history.current.query;
                    delete query.code;
                    delete query.state;
                    console.log(query, "queryquery");
                    console.log(
                      that.$router.history.current.path,
                      "pathpathpath"
                    );
                    let url = `${
                      that.$router.history.current.path
                    }?${utils.urlEncode(query)}`;

                    console.log(url, "urlurl");
                    that.$router.replace(url);
                  });
                } else {
                  alert(res.msg);
                }
              });
            } else if (resp.code === 1000) {
              // let url = escape(window.location.href);
              let sku = utils.getUrlKey("sku");
              let query = sku ? "?sku=" + sku : "";
              let url =
                window.location.origin + window.location.pathname + query;
              let scope = "snsapi_userinfo";
              //WeChatAuth
              window.location.href =
                "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                appId +
                "&redirect_uri=" +
                url +
                "&response_type=code&scope=" +
                scope +
                "&state=STATE#wechat_redirect";
            }
          });
        }
      });
    },

    // 友盟事件埋点
    umengSetMetaInfo(userData) {
      // 友盟埋点，设置userid
      window.aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_id", userData.id], //值为业务自定义的登录账号ID
      });
      // 友盟埋点，设置unionid
      window.aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-idtype", "unionid"], //取值参考见附表1
      });
      window.aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["unionid", userData.unionid], //值为业务自定义的登录账号ID
      });
      // 页面曝光事件
      window.aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [{ is_auto: false }],
      });
    },
  },
};
</script>

<style></style>
