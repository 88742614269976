import Cookies from 'js-cookie'

const TokenKey = 'more-user-token';
const WxUserDataKey = 'more-user-wx-data';
const UserDataKey = 'more-user-data';
const ShareUserDataKey = 'more-share-user-data';

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, {expires: 2})
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getWxUserData() {
    return Cookies.get(WxUserDataKey)
}

export function setWxUserData(wxUserData) {
    return Cookies.set(WxUserDataKey, JSON.stringify(wxUserData), {expires: 2})
}

export function getUserData() {
    return Cookies.get(UserDataKey)
}

export function setUserData(userData) {
    return Cookies.set(UserDataKey, userData, {expires: 2})
}

export function getShareUserData() {
    return Cookies.get(ShareUserDataKey)
}

export function setShareUserData(userData) {
    return Cookies.set(ShareUserDataKey, userData, {expires: 2})
}

export function removeAll() {
    Cookies.remove(TokenKey);
    Cookies.remove(WxUserDataKey);
    Cookies.remove(UserDataKey);
    return true;
}
