/*
 * @Author: AnstJJ
 * @Date: 2024-05-20 16:17:36
 * @LastEditors: AnstJJ
 * @LastEditTime: 2024-05-20 17:01:43
 * @Description: 请填写简介
 */
import axios from 'axios'
import utils from '@/utils/utils'
import md5 from 'js-md5';
import project_config from '@/config/config';
import { getToken } from '@/utils/auth'; // 验权
// import { postLog } from '@/api/api';
import weui from "weui.js";

// 创建axios实例
const service = axios.create({
    // baseURL: process.env.BASE_API, // api的base_url
    timeout: 60000,// 请求超时时间
})

let userToken = utils.getUrlKey('token');
// request拦截器
service.interceptors.request.use(
    config => {
        let timestamp = Date.parse(new Date()).toString().substr(0, 10);
        let noncestr = utils.randomString(32);
        let user_token = (getToken() !== '0' && getToken()) ? getToken() : (userToken ? userToken : '');
        let signature_params = [project_config.api_securit, timestamp, noncestr, user_token];
        config.headers['timestamp'] = timestamp;
        config.headers['noncestr'] = noncestr;
        config.headers['token'] = user_token;
        config.headers['signature'] = md5(signature_params.sort().join('')).toString();
        config.headers['version'] = project_config.version;
        // config.headers['Content-Type'] = "multipart/form-data";
        // let wxUserOpenId = JSON.parse(getWxUserData()).openid;   //Michael说不用传
        // config.headers['devicetoken'] = wxUserOpenId;            //Michael说不用传
        config.headers['device'] = 'WECHAT';
        return config
    },
    error => {
        // Do something with request error
        Promise.reject(error)
    }
)

// respone拦截器
service.interceptors.response.use(
    response => {
        // 废弃了
        /*if (response.data.code !== 0 && response.data.code !== 1100  && response.data.code !== 1000) {
            postLog(JSON.stringify(response.data));
            // weui.alert(response.data.msg);
        }*/
        if (response.data.code === -1) {
            weui.alert(response.data.msg)
        }
        return response.data
    },
    error => {
        return error.response.data
        // return Promise.reject(error)
    }
);

export default service
