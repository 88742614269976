/*
 * @Author: AnstJJ
 * @Date: 2024-05-20 15:32:11
 * @LastEditors: AnstJJ
 * @LastEditTime: 2024-07-25 18:17:42
 * @Description: 请填写简介
 */
import app_logo from '@/assets/app_logo.png';

export default {
    wxShareConfig(title, desc, link, imgUrl) {
        let shareTitle = (title !== '' ? title : '墨尔智慧');
        let shareDesc = (desc !== '' ? desc : '');
        let shareLink = (link !== '' ? link : '墨尔智慧');
        let shareImgUrl = ((imgUrl !== 0 && imgUrl !== '') ? imgUrl : location.origin + '/' + app_logo);
        let wx = window.wx;
        wx.ready(function () {
            wx.updateAppMessageShareData({
                title: shareTitle, // 分享标题
                desc: shareDesc, // 分享描述
                link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImgUrl, // 分享图标
                success: function () {
                    // 设置成功
                }
            });
            wx.updateTimelineShareData({
                title: shareTitle, // 分享标题
                link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImgUrl, // 分享图标
                success: function () {
                    // 设置成功
                }
            });
            wx.onMenuShareTimeline({
                title: shareTitle, // 分享标题
                link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImgUrl, // 分享图标
                success: function () {
                    // 用户点击了分享后执行的回调函数
                },
            });
            wx.onMenuShareAppMessage({
                title: shareTitle, // 分享标题
                desc: shareDesc, // 分享描述
                link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareImgUrl, // 分享图标
                success: function () {
                    // 用户点击了分享后执行的回调函数
                }
            });
            wx.onMenuShareQQ({
                title: shareTitle, // 分享标题
                desc: shareDesc, // 分享描述
                link: shareLink, // 分享链接
                imgUrl: shareImgUrl, // 分享图标
                success: function () {
                    // 用户确认分享后执行的回调函数
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });
            wx.onMenuShareQZone({
                title: shareTitle, // 分享标题
                desc: shareDesc, // 分享描述
                link: shareLink, // 分享链接
                imgUrl: shareImgUrl, // 分享图标
                success: function () {
                    // 用户确认分享后执行的回调函数
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });
        });
    },
    wxBrowser() {
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match('micromessenger') && ua.match('micromessenger').toString() === 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }
}
